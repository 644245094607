<template>
    <page-title
        icon="bi-gear"
        title="製品表示項目設定"
    >
    </page-title>

    <inline-loader v-if="loading"></inline-loader>
    <template v-else>
        <section class="section">
            <form @submit.prevent="save">
                <h4>基本情報</h4>
                <div class="d-flex flex-row flex-wrap gap-1 mb-5">
                    <form-checkbox
                        v-model="preference.public_type"
                        option_label="公開区分"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.availability"
                        option_label="ステータス"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.pending_date"
                        option_label="取引停止日"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.is_each_time"
                        option_label="都度見積"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.regular_price"
                        option_label="販売価格"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.parts_number"
                        option_label="Parts No."
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.model_number"
                        option_label="製品型式"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.production_type"
                        option_label="生産区分"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.is_on_catalog"
                        option_label="カタログ掲載"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.is_price_on_catalog"
                        option_label="カタログ金額掲載"
                    ></form-checkbox>
                    <form-checkbox
                        v-if="$store.getters['auth/canViewProduction']()"
                        v-model="preference.production_cost"
                        option_label="原価"
                        optional_label_class="text-danger"
                    ></form-checkbox>
                    <form-checkbox
                        v-if="$store.getters['auth/canViewProduction']()"
                        v-model="preference.production_hours"
                        option_label="作業時間"
                        optional_label_class="text-danger"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.product_buffer"
                        option_label="PB"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.product_buffer_limit"
                        option_label="PB該当数量"
                    ></form-checkbox>
                    <form-checkbox
                        v-if="$store.getters['auth/canViewProduction']()"
                        v-model="preference.product_buffer_red"
                        option_label="PB(R)"
                        optional_label_class="text-danger"
                    ></form-checkbox>
                    <form-checkbox
                        v-if="$store.getters['auth/canViewProduction']()"
                        v-model="preference.product_buffer_yellow"
                        option_label="PB(Y)"
                        optional_label_class="text-danger"
                    ></form-checkbox>
                    <form-checkbox
                        v-if="$store.getters['auth/canViewProduction']()"
                        v-model="preference.product_buffer_green"
                        option_label="PB(G)"
                        optional_label_class="text-danger"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.field_1"
                        option_label="項目１"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.field_2"
                        option_label="項目２"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.field_3"
                        option_label="項目３"
                    ></form-checkbox>
                </div>

                <h4>規格情報</h4>
                <div class="d-flex flex-row flex-wrap gap-1 mb-5">
                    <form-checkbox
                        v-model="preference.catalogs_specs"
                        option_label="カタログ図用規格"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.standard_specs"
                        option_label="規格図用規格"
                    ></form-checkbox>
                    <form-checkbox
                        v-if="$store.getters['auth/canViewProduction']()"
                        v-model="preference.production_specs"
                        option_label="製作図用規格"
                        optional_label_class="text-danger"
                    ></form-checkbox>
                </div>

                <h4>価格情報</h4>
                <div class="d-flex flex-row flex-wrap gap-1 mb-5">
                    <form-checkbox
                        v-model="preference.wholesale_rate_id"
                        option_label="掛率記号"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.distributor_rate"
                        option_label="代理店掛率"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.distributor_price"
                        option_label="代理店価格"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.designated_distributor_rate"
                        option_label="指定代理店掛率"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.designated_distributor_price"
                        option_label="指定代理店価格"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.group_company_rate"
                        option_label="グループ会社掛率"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.group_company_price"
                        option_label="グループ会社価格"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.shipping_rate"
                        option_label="工場出し掛率"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.shipping_price"
                        option_label="工場出し価格"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.factory_rate"
                        option_label="工場掛率"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.factory_price"
                        option_label="工場価格"
                    ></form-checkbox>
                </div>

                <h4>商社別情報</h4>
                <div class="d-flex flex-row flex-wrap gap-1 mb-5">
                    <form-checkbox
                        v-model="preference.is_retailer_selling"
                        option_label="商社別販売ステータス"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.retailer_product_code"
                        option_label="商社別コード"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.retailer_product_name"
                        option_label="商社別製品名"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.retailer_rate"
                        option_label="商社別掛率"
                    ></form-checkbox>
                    <form-checkbox
                        v-model="preference.retailer_price"
                        option_label="商社別価格"
                    ></form-checkbox>
                </div>
                    
                <div class="col d-flex justify-content-between">
                    <button-exec-update
                        size="btn-lg"
                        text="更新"
                    ></button-exec-update>
                </div>
            </form>
        </section>
    </template>
</template>

<script>
import UserPreference from '@/models/entities/user-preference'
import PageTitle from '@/components/PageTitle.vue';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate';
import FormCheckbox from '@/components/forms/FormCheckbox';

export default {
    name: 'Preference',
    components: {
        InlineLoader,
        PageTitle,
        ButtonExecUpdate,
        FormCheckbox,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            loading: true,

            //製品表示項目
            preference: null,
        }
    },
    mounted() {
        this.$http.get(`/me/preference`)
        .then(response => {
            this.preference = new UserPreference(response.data);
        })
        .finally(() => {
            this.loading = false;
        });
    },
    computed: {
    },
    methods: {
        save() {
            this.startScreenLoading();

            //ログイン中のユーザが「製作情報閲覧権限」を持たない場合、「原価」「製作図用規格」のチェックボックスは常に0が入る
            if (!this.$store.getters['auth/canViewProduction']()) {
                this.preference.production_cost = 0;
                this.preference.production_specs = 0;
            }

            this.$http.put(`/me/preference`, this.preference)
            .then(() => {
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
